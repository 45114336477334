<template>
  <div class="container">
    <!-- <div class="upper-div"></div> -->
    <div class="middle-div"></div>
    <!-- <div class="lower-div"></div> -->
  </div>
</template>

<script>
export default {
  name: 'FootEr',
}
</script>

<style>
/* 设置 <div> 元素的高度 */
.container {
  position: relative;
}

/* .upper-div,
.middle-div,
.lower-div {
  height: 1000px;
} */

/* 设置背景颜色 */
.upper-div {
  background-color: #3498db;
}

.middle-div {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 85%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-size: 100% auto;
  background-position: center;
  background-image: url('https://pub-f4c254af1d7a47498e60e4a7155795c9.r2.dev/photo.jpg'); /* 设置中间 <div> 的背景图 */
  z-index: -2; /* 将中间背景图置于下层 */
  /* background-repeat: repeat; */
}

.lower-div {
  background-color: #e74c3c;
}
.traslate-div{
  height: 100vh;
  /* background-color: #D044FF; */
  z-index: -3;
  background-color: white;
}
</style>