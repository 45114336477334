<template>
  <v-app>
    <v-main>
      <HelloWorld/>
      <!-- <AppBar></AppBar>
      <router-view></router-view>
      <FootEr></FootEr> -->
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
// import AppBar from './components/layout/appbar_temp.vue'
// import FootEr from './components/layout/footer_temp.vue'
import 'swiper/swiper-bundle.css';
export default {
  name: 'App',
  components: {
    HelloWorld,
    // AppBar,
    // FootEr
  },
  data: () => ({}),
}
</script>
