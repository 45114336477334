<template>
  <div class="fixed-top-title-bar">
    <!-- Add your title and other content here -->
    <v-row class="title-row"> 
      <v-col class="left-col">
        <v-avatar style="border-radius: 0;width:8vw" id="logoc">
          <v-img src="https://instafun-r2.huskymobile.com/title.png"></v-img>
        </v-avatar>
        <span class="title">&nbsp;instafun.io</span>
       </v-col>
      <v-col class="right-col">
        <span :class="{'navigation': true, 'active': activeButton === 'games'}" @click="scrollToSection('games')" id="games_title">Games</span>
        <span :class="{'navigation': true, 'active': activeButton === 'about_us'}" @click="scrollToSection('about_us')" id="about_us_title">About Us</span>
        <span :class="{'navigation': true, 'active': activeButton === 'career'}" @click="scrollToSection('career')" id="career_title">Career</span>
        <span :class="{'navigation': true, 'active': activeButton === 'contact'}" @click="scrollToSection('contact')" id="contact_title">Contact</span>
        <!-- <span class="navigation" @click="scrollToSection('games')" id="games_title">Games</span>
        <span class="navigation" @click="scrollToSection('about_us')" id="about_us_title">About Us</span>
        <span class="navigation" @click="scrollToSection('career')" id="career_title">Career</span>
        <span class="navigation" @click="scrollToSection('contact')" id="contact_title">Contact</span> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FixedTopTitleBar',
  data:() => ({
    anchors : '',
    currentAnchor: '', // 初始化为第一个猫店
    initAnchor: '', // 初始化为第一个猫店
    ending: false,
    isScrollListening: true,
    activeButton: null,
    scrollTimeout: null // 添加计时器变量
  }),
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      this.isScrollListening = false;
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        this.activeButton = sectionId
      }
      // 在点击元素后，等待一小段时间再次启用滚动监听器
    clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
      this.isScrollListening = true;
    }, 1000);
    },
    handleScroll() {
      if (!this.isScrollListening) {
        return;
      }
      const targetElements = ['games', 'about_us', 'career', 'contact'];
      for (const targetId of targetElements) {
        const targetElement = document.getElementById(targetId);
        if (targetElement && this.isElementInViewport(targetElement)) {
          this.activeButton = targetId
        }
      }
    },
    isElementInViewport(element) {
      const rect = element.getBoundingClientRect();
      // alert((rect.bottom - 120) + '=>' + document.documentElement.clientHeight + '=>' + window.innerHeight)
      let del_value = 110
      if (element.id === 'career') {
        del_value = 120
      }
      return (
        rect.top >= 0 &&
        (rect.bottom - del_value) <= (window.innerHeight || document.documentElement.clientHeight) 
      );
    },
    // scrollToSection(sectionId) {
    //   //  添加选中状态的样式
    //   const activeTags = document.getElementsByClassName('active');
    //   for (const element of activeTags) {
    //     element.classList.remove('active');
    //   }
    //   event.target.classList.add('active');
    //   // 获取要跳转到的元素
    //   const element = document.getElementById(sectionId);

    //   if (element) {
    //     if (element.id == 'contact') {
    //       this.ending = true
    //     } else {
          
    //       this.ending = false
    //     }
    //     const rect = element.getBoundingClientRect();
    //     const x = rect.left + window.scrollX;  // 元素相对于文档的横坐标
    //     let y = rect.top + window.scrollY;   // 元素相对于文档的纵坐标
    //     element.scrollIntoView({ behavior: "smooth" });
    //     setTimeout(function() {
    //       window.scrollTo(x, y);
    //     }, 260); 
    //   }
    // },
    // scrollEvent() {
    //   // 遍历所有锚点，找到当前可见的锚点
    //   for (const element of this.anchors) {
    //     if (this.isElementInViewport(element)) {
    //       this.currentAnchor = element
    //     }
    //   }

    //   this.switchToCatStore(this.currentAnchor);
    // },
    // switchToCatStore(anchor) {
    //   // 模拟切换到对应猫店的逻辑
    //   const activeTags = document.getElementsByClassName('active');
    //   const activeTagsArray = Array.from(activeTags);
    //   for (const element of activeTagsArray) {
    //     element.classList.remove('active')
    //   }
    //   let changeEle = document.getElementById(anchor.id + '_title');
    //   changeEle.classList.add('active')
    //   console.log(anchor.id)
    // },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.anchors = document.querySelectorAll('[data-cat-store]');
    this.currentAnchor = this.anchors[0]; // 初始化为第一个猫店
    this.initAnchor = this.anchors[0]; // 初始化为第一个猫店
    // window.addEventListener('scroll', this.scrollEvent);
  },
};
</script>

<style>
@media (max-width: 280px) {
  .navigation {
    margin-left: 1vw;
  }
  .title-row {
    padding: 0vw 1vw 0vw 1vw;
  }
}
@media (min-width: 281px) {
  .navigation {
    margin-left: 4vw;
  }
  .title-row {
    padding: 0vw 2vw 0vw 2vw;
  }
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-col {
  display: flex;
  align-items: center;
}

.right-col {
  /* 可以根据需要设置右侧列的宽度 */
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'MyCustomFont-MV', sans-serif;
  white-space: nowrap;
  font-size: 1.3vw;
  color: rgb(67,23,160);
  margin-top: 1.05vw;
  /* font-weight: bold; */
}

.title{
  font-family: 'MyCustomFont-MB', sans-serif;
  background-color: #f0f0f0;
  /* 设置文字容器内文字的颜色为透明，将渐变色作为背景显示 */
  color: transparent;
  /* 设置文字容器的背景渐变色 */
  background-image: linear-gradient(to right,rgb(67,23,160), rgb(255,52,245));
  /* 设置文字容器的背景区域为文字本身，实现渐变色字体效果 */
  background-clip: text;
  /* 设置文字容器内文字不可选中 */
  user-select: none;
  /* 设置文字的字体大小和样式（可选） */
  font-size: 1.5vw;
  font-weight: bold;
  margin-left: 1vw;
}
.navigation {
  /* 添加默认下划线样式，这里使用border-bottom实现下划线 */
  border-bottom: 5px solid transparent;
  /* 这里使用transition属性，当下划线出现或消失时会有一个平滑过渡效果 */
  transition: border-color 0.2s ease-in-out, font-weight 0.2s ease;
  /* margin-left: 4vw; */
  /* text-rendering: optimizeLegibility; */
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); */
  /* backface-visibility: hidden; */
}
/* .navigation:hover {
  color: rgb(255,52,245);
  border-color: rgb(255,52,245);
  font-weight: bold;
  cursor: pointer;
} */
.active {
  color: rgb(255,52,245);
  border-color: rgb(255,52,245);
  font-weight: bold;
  cursor: pointer;
}
.fixed-top-title-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color:white; /* Add your desired background color */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow for a subtle effect */
  z-index: 1000; /* Make sure it's above other elements */
}
</style>