import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import { loadFonts } from './plugins/webfontloader'
import VueScrollactive from 'vue-scrollactive'
import './font-preload.css'

// loadFonts()

createApp(App)
  .use(vuetify)
  .use(VueScrollactive)
  .mount('#app')
