<template>
  <link rel="preload" href="https://instafun-r2.huskymobile.com/font-family-only/MontserratAlternates-Medium.ttf" as="font" type="font/ttf" crossorigin>
  <link rel="preload" href="https://instafun-r2.huskymobile.com/font-family-only/MontserratAlternates-Black.ttf" as="font" type="font/ttf" crossorigin>
  <link rel="preload" href="https://instafun-r2.huskymobile.com/font-family-only/Manrope-VariableFont_wght.ttf" as="font" type="font/ttf" crossorigin>
  <link rel="preload" href="https://instafun-r2.huskymobile.com/font-family-only/Manrope-Bold.ttf" as="font" type="font/ttf" crossorigin>
  <v-app>
    <div style="background-color: white;">
      <FixedTopTitleBar />
      <div class="video-text">
        <div class="back-video">
          <video autoplay loop muted playsinline>
            <source src="https://instafun-r2.huskymobile.com/video.mp4" type="video/mp4">
            您的浏览器不支持视频播放。
          </video>
          <div class="video-mask">
            <div class="together-all">
              <div>
                <h1 class="together-title">
                  Together we make an extraordinary impact!
                </h1>
              </div>
              <div class="together-content">
                <span>
                  instafun is about embracing experiences and expressing emotions. Live every moment and let us create amazing games!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <v-container> -->
      <div style="text-align: center;width:94%;margin-left: 3vw;">
        <v-row >
          <v-col
            cols="4"
            md="4"
            lg="4"
            v-for="(item, i) in services"
            :key="i"
          >
          <div class="item-container" id="my-element">
            <div class="number-change" >
              <count-to v-if="isCountToVisible" :start-val="0" :decimals="item.decimals" :end-val="item.number" :duration="6000">
              </count-to>{{ item.subtitle }}
            </div>
            <h4 id="number-text">
              {{ item.info }}
            </h4>
          </div>
          </v-col>
        </v-row>
        <div class="pt-12 text-center" style="color: rgb(23, 3, 94);font-family: 'MyCustomFont-MV', sans-serif;">
          <h1 class="display-1 font-weight-blod" id="number-text-info" style="margin-bottom: 8vw;margin-top: 3vw;">
            We are proud to have engaged hundreds of millions of people globally through our games and achieved a monumental feat of 1.8 billions downloads!
          </h1>
        </div>
      </div>
      <!-- </v-container> -->
      <div class="app-info">
        <v-row>
          <v-col cols="6" md="6" lg="6">
            <v-col class="temp1">
              <v-col class="temp3"></v-col>
            </v-col>
          </v-col>
          <v-col cols="6" md="6" lg="6">
            <v-col class="temp2">
              <v-col class="temp4"></v-col>
            </v-col>
          </v-col>
        </v-row>
        <div id="games"  style="margin-top:-4.5vw;" data-cat-store="store1"></div>
        <v-container class="d-flex justify-center align-center" style="margin-top:-4.5vw;" >
          <div class="pt-12 text-center">
            <h3 class="app-title">
              Our Top Games
            </h3>
            <v-row justify="center" class="mt-5" style="margin-left: 1vw;">
              <v-col cols="6" md="3" lg="3" sm="3" v-for="(item, i) in currentPageData" :key="i" class="app-body">
                <div style="box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.5);border-radius: 30px; width: 95%;">
                    <img
                      :src="`https://instafun-r2.huskymobile.com/${item.src}.png`"
                      id="app-img-status-not-hide"
                      :alt="item.src"
                      style="width: 100%; height: auto;vertical-align: middle;"
                    />
                    <div class="content-not-hide">
                      <div class="content-not-hide-content">
                        <p class="app-head"> Minutes of Play Time</p>
                        <p class="app-head-title"> {{ item.name }}</p>
                        <div style="display: flex; align-items: flex-start;">
                          <div style="width: 35%;margin-top: 0.4vw;" class="apple-img">
                            <img 
                              :src="item.appleIcon"
                              alt="app"
                              @click="to_other_html(i,'apple')"
                              style="max-width: 100%; max-width:100%;"
                              @mouseover="changeAppimage('apple', 'over', i)"
                              @mouseout="changeAppimage('apple', 'leave', i)"
                              class="hover-img"
                              />
                          
                          </div>
                          <div style="width: 35%; margin-left: 0.8vw;background-color: transparent;margin-top: 0.4vw;margin-bottom: 0.5vw;" class="google-img">
                            <img 
                              :src="item.googleIcon" 
                              alt="google" 
                              @click="to_other_html(i,'google')" 
                              style="max-width: 100%; max-width:100%;"
                              class="hover-img"
                              @mouseover="changeAppimage('google', 'over', i)"
                              @mouseout="changeAppimage('google', 'leave', i)"
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </v-col>
            </v-row>
            <v-col class="pages-choose">
              <div v-for="pageNumber in totalPages" :key="pageNumber">
                <button @click="changePage(pageNumber)" :class="getButtonClass(pageNumber)" class="app-choose-button" >
                  {{ pageNumber }}
                </button>
              </div>
            </v-col>
          </div>
        </v-container>
        <!-- <v-row class="app-footer" style="justify-content: center;">
        </v-row> -->
      </div>
    </div>
    <div class="comment" style="background-color: white;">
      <div style="margin-bottom: 3vw;margin-top: 8vh;">Thousand of Game Players Love Us</div>
      <!-- :breakpoints="{ 768:{slidesPerView:3, slidesPerGroup:3}}" -->
      <swiper
        :slidesPerView="2"
        :slidesPerGroup="2"
        :loop="true"
        :pagination="{clickable: true}"
        :autoplay="{ delay: 5000,disableOnInteraction: false}"
        :modules="modules"
        style="width: 80%; /* 设置容器宽度 */"
        class="comment-swiper"
        :breakpoints="{ 768:{slidesPerView:3, slidesPerGroup:3}}"
        >
          <swiper-slide v-for="(comment, index) in comments" :key="index">
            <img :src="`https://instafun-r2.huskymobile.com/new_card/${comment}.png`" :alt="comment" style="width: 100%; height: auto;"/>
          </swiper-slide>
      </swiper>
    </div>
    <div style="background-color: white;">
      <div id="about_us"  data-cat-store="store2"></div>
      <div class="begin-header" style=" margin-top: 10vw;position: relative;">
        <swiper
          :slidesPerView="1"
          :effect="'fade'"
          :loop="true"
          :pagination="{clickable: true}"
          :autoplay="{ delay: 2000,disableOnInteraction: false}"
          :modules="modules"
          >
            <swiper-slide v-for="(image, index) in images" :key="index">
              <div class="image-container" :style="image.backgroud_color">
                <img :src="`https://instafun-r2.huskymobile.com/${image.h_img}/${image.h_img}.png`"  class="cropped-image" :alt="image.h_img"/>
                
                <v-card-text class="fill-height d-flex align-end" style="z-index: 2;">
                  <v-row class="flex-column">
                    <v-col cols="6" md="4" lg="6" sm="6" class="header-info">
                      <h2>
                        <v-row>
                          <span class="header-title" style="white-space: pre-line;">
                            {{ image.title }}
                          </span>
                        </v-row>
                        <v-row>
                          <span class="header-content">
                            {{ image.content }}
                          </span>
                        </v-row>
                      </h2>
                    </v-col>
                    <v-col class="image-container-role" style="z-index: 3;">
                      <img :src="`https://instafun-r2.huskymobile.com/${image.l_img}/a.png`" :alt="image.l_img" style="width: 100%;height: auto;"/>
                    </v-col> 
                    <v-col class="image-container-role" style="z-index: 2;">
                      <img :src="`https://instafun-r2.huskymobile.com/${image.l_img}/b.png`" :alt="image.l_img" style="width: 100%;height: auto;"/>
                    </v-col> 
                    <v-col class="image-container-role" style="z-index: 1;">
                      <img :src="`https://instafun-r2.huskymobile.com/${image.l_img}/c.png`" :alt="image.l_img" style="width: 100%;height: auto;"/>
                    </v-col> 
                    <v-col v-if="image.extral" class="image-container-role" style="z-index: 1;">
                      <img :src="`https://instafun-r2.huskymobile.com/${image.l_img}/d.png`" :alt="image.l_img" style="width: 100%;height: auto;"/>
                    </v-col> 
                    <v-col v-if="image.extral" class="image-container-role" style="z-index: 1;">
                      <img :src="`https://instafun-r2.huskymobile.com/${image.l_img}/e.png`" :alt="image.l_img" style="width: 100%;height: auto;"/>
                    </v-col> 
                    <!-- <v-col class="image-container-role">
                      <v-img :src="require(`../assets/${image.l_img}/a.png`)" style="margin-top: 1.2vw;"></v-img>
                      <v-img :src="require(`../assets/${image.l_img}/b.png`)" style="margin-top: 1.8vw;"></v-img>
                      <v-img :src="require(`../assets/${image.l_img}/c.png`)" style="margin-top: 0.5vw;"></v-img>
                    </v-col> -->
                  </v-row>
                </v-card-text>
              </div>
            </swiper-slide>
        </swiper>
      </div>
      <div class="our-people">
        <h1>Our People</h1> 
        <v-row v-for="(row, rowIndex) in displayedRows" :key="rowIndex" class="people-image">
          <v-col 
            v-for="(imageUrl, colIndex) in getImagesForRow(rowIndex)" 
            :key="colIndex"
            :lg="getColWidth()"
            :cols=3
            :sm="getColWidth()"
            class="people-image-column">
            <img :src="`https://instafun-r2.huskymobile.com/new_card3/${imageUrl}.png`" alt="Image" style="width: 100%;height: auto;"/>
          </v-col>
        </v-row>
        <div style="margin-top: 3vw;">
          <!-- <v-button  
            class="show-btn"
            @mouseover="commentover"
            @mouseout="commentout"
            @click="toggleDisplay"
            :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</v-button> -->
            <img
              class="show-btn"
              @mouseover="commentover"
              @mouseout="commentout"
              @click="toggleDisplay"
              :src="backgroundImage"
              :alt="backgroundImage"/>
          <!-- <span class="show-btn-text" @click="toggleDisplay" style="display: none;">{{ showMore ? 'Hide' : 'Meet More of the Crew' }}</span> -->
          <!-- <v-avatar size="20vh"
            style="border-radius: 0;" 
            @mouseover="commentover"
            @mouseout="commentout"
            @click="toggleDisplay">
            <v-img :src="backgroundImage"></v-img>
          </v-avatar> -->
        </div>
      </div>
    </div>
    <div class="traslate-div"></div>
    <div style="background-color: white;">
      <div class="ball-back">
        <div class="join-all" >
          <div id="career"  data-cat-store="store3"></div>
          <div class="join-us-both">
            <div class="join-info-header">
              <h1>
              Join Us
              </h1>
              <span class="join-info-content">
                Great people make a greater difference
              </span>
            </div>
            <v-row>
              <v-col cols="12" md="12" lg="12" v-for="(item, i) in joinsinfo" :key="i" style="margin-top:5vw;">
                <div>
                  <v-row>
                    <!-- <v-col cols="12"> -->
                    <div class="d-flex align-center">
                      <v-avatar color="accent" size="2.5vw">
                        <img :src="`https://instafun-r2.huskymobile.com/${item.icon}.png`" :alt="item.icon" style="width: 100%;height: auto;"/>
                      </v-avatar>
                      <span style="margin-right: 2vw; "></span>
                      <p class="join-info-title" id="galaxy-fold-join-title">{{ item.header }} </p>
                    </div>
                    <!-- </v-col> -->
                    <!-- <v-col cols="12">
                    <div class="d-flex align-center" id="galaxy-fold-join-content">
                      <v-avatar color="accent" size="2.5vw">
                        <v-img></v-img>
                      </v-avatar>
                      <span style="margin-right: 2vw; "></span>
                      <p class="join-info-content">{{ item.info }}</p>
                    </div>
                  </v-col> -->
                  </v-row>
                  <v-row>
                    <div class="d-flex align-center" id="galaxy-fold-join-content">
                      <v-avatar color="accent" size="2.5vw">
                        <!-- <v-img></v-img> -->
                      </v-avatar>
                      <span style="margin-right: 2vw; "></span>
                      <p class="join-info-content">{{ item.info }}</p>
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
        </div>
        </div>
        <div id="contact" data-cat-store="store4"></div>
        <div class="contact-info" >
          <v-container>
            <div>
              <v-row class="contact-all">
                <img src="https://instafun-r2.huskymobile.com/join.png" alt="join" style="max-width:70vw;"/>
              </v-row>
              <v-row style="margin-top: 2vw; display: flex; justify-content: center;">
                <v-col  class="join-star"></v-col>
                <v-col  class="join-star">
                <span id="contact_button">
                  Contact Us: contact@instafun.io
                </span>
              </v-col>
              <v-col class="join-star"></v-col>
              </v-row>
            </div>
            <div style="margin-top: 3vw;color: white; font-family: 'MyCustomFont-MB', sans-serif;">
                <v-avatar color="accent" size="16" style="border-radius: 0;">
                  <img src="https://instafun-r2.huskymobile.com/title_.png" alt="footer_title" style="width: 100%;height: auto;"/>
                </v-avatar>
                <span class="contact-logo">&nbsp;InstaFun</span>
                <span style="text-align: left;font-family: 'MyCustomFont-MM', sans-serif;" class="contact-copyright">
                  Copyright  InstaFun corporation limited. All rights reserved. {{ new Date().getFullYear() }}
                </span>
                <!-- <v-avatar color="accent" size="16" style="border-radius: 0;margin-left: 10vw;">
                  <v-img lazy-src="" src="../assets/title.png"></v-img>
                </v-avatar> -->
            </div>
            <!--
            <div>飞鸟</div>
            <div class="bird-container bird-container--one">
              <div class="bird bird--one"></div>
            </div>
            
            <div class="bird-container bird-container--two">
              <div class="bird bird--two"></div>
            </div>
            
            <div class="bird-container bird-container--three">
              <div class="bird bird--three"></div>
            </div>
            
            <div class="bird-container bird-container--four">
              <div class="bird bird--four"></div>
            </div>
            <div>星星坠落</div>
            <div class="star"></div>
            <div class="star" style="margin-left: 10vw;"></div>
            <div class="star" style="margin-left: 20vw;"></div>
            <div class="star" style="margin-left: 30vw;"></div>
            <div class="star" style="margin-left: 40vw;"></div>
            <div class="star" style="margin-left: 50vw;"></div>
            <div class="star" style="margin-left: 60vw;"></div>
            <div class="star" style="margin-left: 80vw;"></div>
            <div class="star" style="margin-left: 80vw;"></div> -->
          </v-container>
        </div>
      </div>
    </div>
    <FootEr></FootEr>
  </v-app>
</template>

<script >
import { CountTo } from 'vue3-count-to';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, Pagination, A11y, EffectFade  } from 'swiper'
import FixedTopTitleBar from './layout/appbar_temp.vue'; // Import the component
import FootEr from './layout/footer_temp.vue'; // Import the component
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
export default {
  name:'HelloWorld',
  components: {
    CountTo,
    Swiper,
    FixedTopTitleBar,
    SwiperSlide,
    FootEr
  },
  data: () => ({
    showOverlay: [false,false,false,false,false,false],
    isCountToVisible: false,
    modules : [Autoplay, Pagination, Navigation, A11y, EffectFade],
    services: [
      {
        number: 1.8,
        info: "Downloads",
        subtitle: "B+",
        decimals: 1
      },
      {
        number: 800,
        info: "Monthly Active Users",
        subtitle: "M+",
        decimals: 0
      },
      {
        number: 7,
        info: "5-Star Rating",
        subtitle: "M+",
        decimals: 0
      },
    ],
    apps: [
      {
        src: 'sim009',
        name: "Makeup Slime",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/us/app/x/id1611124134",
        google_url:"https://play.google.com/store/apps/details?id=makeup.slime.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'vpink006',
        name: "Surprise Doll: Mermaid",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/us/app/x/id1637778156",
        google_url:"https://play.google.com/store/apps/details?id=color.reveal.mermaid.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'sim015',
        name: "Cake Chef",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/us/app/x/id1620292244",
        google_url:"https://play.google.com/store/apps/details?id=cake.making.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'vpink002',
        name: "Surprise Doll: Unbox",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/us/app/x/id1636270405",
        google_url:"https://play.google.com/store/apps/details?id=color.reveal.lol.dolls.suprise.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src: 'toca002',
        name: "Princess World",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/us/app/x/id1638068385",
        google_url:"https://play.google.com/store/apps/details?id=princess.life.girl.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'sim010',
        name: "Frozen Honey",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/app/id1615518089",
        google_url:"https://play.google.com/store/apps/details?id=frozen.honey",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'vpink001',
        name: "Makeup Organizing",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/app/id1619940335",
        google_url:"https://play.google.com/store/apps/details?id=restock.makeup.games",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
      {
        src:  'sim006',
        name: "Makeup Slime 2",
        downds: "+1.8B",
        active: "+800M",
        star: "+7M",
        apple_url:"https://apps.apple.com/app/id1566999911",
        google_url:"https://play.google.com/store/apps/details?id=makeup.slime",
        appleIcon: "https://instafun-r2.huskymobile.com/new_card/app.png",
        googleIcon: "https://instafun-r2.huskymobile.com/new_card/google.png",
        style: {}
      },
    ],
    comments:[1,2,3,4,5,6,7,8,9],
    peoples:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
    imagesPerRow:7,
    imageOfRows: 7, // 总共的行数，根据实际情况调整
    displayedRows: 7, // 初始显示的行数
    showMore: false, // 控制显示更多的标志
    totalPages:2,
    itemsPerPage:4,
    currentPage:1,
    currentPageData:[],
    joinsinfo: [
      {
        icon: '1',
        header: "We value play & inclusion",
        info: "Everything we do we do it in a fun & inclusive environment",
      },
      {
        icon: '3',
        header: "Humble & Evolving",
        info: "Always humble, always learning and always seeking new ideas",
      },
      {
        icon: '5',
        header: "Maximum Teamwork",
        info: "We encourage and support one another with vision, wisdom & knowledge",
      },
      // {
      //   icon: '5',
      //   header: "Have Fun",
      //   info: "When you enjoy what you do you create extraordinary things",
      // },
      // {
      //   icon: '4',
      //   header: "Make an impact",
      //   info: "Always be creative & join the team to make the next hit game",
      // },
    ],
    images: [
      {
        h_img: '1',
        l_img: "1",
        backgroud_color: 'background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));',
        title:'We Create & \n Publish Games',
        content:'Billions of Downloads & Lovers',
      },
      { 
        h_img: '2',
        l_img: "2",
        backgroud_color: 'background-image: linear-gradient(to right, rgb(0,74,251), rgb(0,255,170));',
        title:'Always Be \n Instant & Fun',
        content:'Produce Amazing Games',
      },
      { 
        h_img: '3',
        l_img: "3",
        backgroud_color: 'background-image: linear-gradient(to right, rgb(144,1,255), rgb(255,33,148));',
        title:'Stay Ahead \n Stay Innovative',
        content:'From Idea to Success',
      },
      { 
        h_img: '4',
        l_img: "4",
        backgroud_color: 'background-image: linear-gradient(to right, rgb(100,3,243), rgb(132,148,242));',
        title:'Go Global & \n Make An Impact',
        content:'Compete on the Global Stage',
        extral:true,
      },
      { 
        h_img: '5',
        l_img: "5",
        backgroud_color: 'background-image: linear-gradient(to right, rgb(67,23,160), rgb(255,52,245));',
        title:'Digital & Mobile \n Trends Focused',
        content:'Shape the Next Hit Product',
        extral:true,
      },
      ],
    backgroundImage: 'https://instafun-r2.huskymobile.com/new_card3/Meetourteam.svg', // 默认
    hoverImage1: 'https://instafun-r2.huskymobile.com/new_card3/Meetourteam_.svg', // 悬停背景图
    clickedImage1: 'https://instafun-r2.huskymobile.com/new_card3/Hide.svg', // 点击背景图
    hoverImage2: 'https://instafun-r2.huskymobile.com/new_card3/Hide_.svg', // 悬停背景图
    clickedImage2: 'https://instafun-r2.huskymobile.com/new_card3/Meetourteam.svg', // 点击背景图
    activeBackground: 'https://instafun-r2.huskymobile.com/choose2.png',
    defaultBackground: 'https://instafun-r2.huskymobile.com/choose1.png',
    appleIconOver: "https://instafun-r2.huskymobile.com/new_card/app_.png",
    googleIconOver: "https://instafun-r2.huskymobile.com/new_card/google_.png",
    appleIconLeave: "https://instafun-r2.huskymobile.com/new_card/app.png",
    googleIconLeave: "https://instafun-r2.huskymobile.com/new_card/google.png",
  }),
  setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
      };
    },
  methods: {
    changeAppimage(appType, status, index){
      let temp_index = (this.currentPage - 1 ) * 4 + index;
      if (status == 'over') {
        if (appType == 'apple') {
          this.apps[temp_index].appleIcon = this.appleIconOver
        } else {
          this.apps[temp_index].googleIcon = this.googleIconOver
        }
      } else {
        if (appType == 'apple') {
          this.apps[temp_index].appleIcon = this.appleIconLeave
        } else {
          this.apps[temp_index].googleIcon = this.googleIconLeave
        }
      }
    },
    getImagesForRow(rowIndex) {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;

      const isMobileResolution = screenWidth <= 768 && screenHeight <= 1024;
      if (isMobileResolution) {
        this.imagesPerRow = 4; // Adjust for small screens (sm and down)
        this.imageOfRows = 7; // 总共的行数，根据实际情况调整
        // this.displayedRows= 2; // 初始显示的行数
      } else {
        this.imagesPerRow = 7; // Adjust for small screens (sm and down)
        this.imageOfRows = 4; // 总共的行数，根据实际情况调整
        // this.displayedRows= 2; // 初始显示的行数
      }
      const startIndex = rowIndex * this.imagesPerRow;
      const endIndex = startIndex + this.imagesPerRow;
      return this.peoples.slice(startIndex, endIndex);
    },
    getColWidth() {
      // this.imagesPerRow = number;
      return Math.floor(12 / this.imagesPerRow);
      // return 3
    },
    toggleDisplay() {
      this.showMore = !this.showMore;
      if (this.showMore) {
        this.displayedRows = this.imageOfRows;
        this.backgroundImage = this.clickedImage1;
      } else {
        this.displayedRows = 2; // 将显示的行数恢复到初始值
        this.backgroundImage = this.clickedImage2;
      }
    },
    commentover() {
      if (this.showMore) {
        this.backgroundImage = this.hoverImage2;
      }else{
        this.backgroundImage = this.hoverImage1;
      }
    },
    commentout() {
      if (this.showMore) {
        this.backgroundImage = this.clickedImage1;
      }else{
        this.backgroundImage = this.clickedImage2;
      }
    },
    toggleOverlay(index) {
        for(let i=0;i < this.showOverlay.length;i++){
        this.showOverlay[i] = false
      }
      this.showOverlay[index] = true
      this.apps[index].style["transform"] = "translateY(-49%)"
    },
    toggleLeavelay() {
      for(let i=0;i < this.showOverlay.length;i++){
        this.showOverlay[i] = false
        this.apps[i].style = {}
      }
    },
    to_other_html(index,type){
      let temp_index = (this.currentPage - 1 ) * 4 + index;
      let url=type=='apple'?this.apps[temp_index].apple_url:this.apps[temp_index].google_url
      window.open(url, '_blank');
    },
    // preloadImages() {
      // let images_back = new Array()  
      // let images_little = new Array()  
      // let images_app = new Array()  
      // let image_comment = new Array()
      // let image_contact = new Array()  
      // let image_people = new Array()  
      // for (let i = 0; i < this.images.length;i++) {
      //   images_back[i] = new Image();
      //   images_back[i].src = require(`../assets/${this.images[i].h_img}/${this.images[i].h_img}.png`);
      // }
      // for (let i = 0; i < this.images.length;i++) {
      //   images_little[i] = new Image();
      //   images_little[i].src = require(`../assets/${this.images[i].l_img}/${this.images[i].l_img}.png`);
      // }
      // for (let i = 0; i < this.apps.length;i++) {
      //   images_app[i] = new Image();
      //   images_app[i].src = require(`../assets/${this.apps[i].src}.png`);
      // }
      // for (let i = 0; i < this.comments.length;i++) {
      //   image_comment[i] = new Image();
      //   image_comment[i].src = require(`../assets/new_card/${this.comments[i]}.png`);
      // }
      // for (let i = 0; i < this.peoples.length;i++) {
      //   image_people[i] = new Image();
      //   image_people[i].src = require(`../assets/new_card3/${this.peoples[i]}.png`);
      // }
      // image_contact[0] = new Image();
      // image_contact[0].src = require(`../assets/join.png`);
      // image_contact[1] = new Image();
      // image_contact[1].src = require(`../assets/contactus.png`);
    // },
    fetchData() {
      // 根据当前页数和每页显示的数据量，计算出数据的起始索引和结束索引
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      // alert(startIndex,endIndex)
      // 从总数据中截取对应页数的数据
      const pageData = this.apps.slice(startIndex, endIndex);
      // 更新当前页的数据
      this.currentPageData = pageData;
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchData(); // 切换页数后重新获取对应的数据
    },
    getButtonClass(page) {
      return {
        'button-clicked': page === this.currentPage,
        'app-choose-button': true
      };
    }
  },
  mounted() {
    // this.preloadImages();
    this.toggleDisplay()
    this.toggleDisplay()
    this.currentPageData = this.apps.slice(0, 4)
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isCountToVisible = true;
        }
        else{
          this.isCountToVisible = false;
        }
      });
    });

    const element = document.getElementById('my-element'); // 将 'my-element' 替换为数字滚动所在元素的ID
    observer.observe(element);
  },
  computed:{
    numberOfRows() {
      return Math.ceil(this.peoples.length / this.imagesPerRow);
    },
  }
  
};
</script>

<style lang="scss">
@media (max-width: 355px){
  .temp1{
    display: none;
  }
  .temp2{
    display: none;
  }
}
 /* 应用于手机的样式 临时整体缩小*/
@media (min-width: 281px)  and (max-width: 767px) {
  .back-video{
    height: 27vh; /* Adjust the height as needed */
  }
  .together-title{
    margin-top: 15vw;
    font-size:7vw;
    line-height: 7vw;
  }
  #app-img-status-not-hide{
    border-radius: 10px 10px 0px 0px; 
  }
  .content-not-hide{
    border-radius: 0px 0px 10px 10px; 
  }
  .temp1{
    margin-top: 0vw;
  }
  .temp2{
    margin-top: 0vw;
  }
  .comment-swiper{
    height: 80%;
  }
  .join-us-both{
    width: 85%;
  }
  .right-col {
    font-size: 2.5vw;
  }
  .title {
    font-size: 3.5vw;
  }
  .together-content{
    margin-top: 4vw;
    font-size: 4vw;
  }
  .number-change{
    font-size: 9vw !important;
    // max-height: 11vw !important;
  }
  #number-text-info{
    font-size: 4vw;
  }
  .app-title{
    font-size: 8vw; 
    margin-top: -3vw;
  }
  .app-body{
    margin-top: -1vh;
    margin-left: -2vw;
  }
  .comment{
    font-size:4.5vw;
  }
  .our-people{
    width: 90%;
    margin: 5vw auto;
    // margin-left: -10vw;
    font-size:4vw;
  }
  .traslate-div{
    height: 100vh;
    // background-image: url("https://instafun-r2.huskymobile.com/new_card3/di.png");
    // background-size: 100% auto;
    // background-position: center;
  }
  .middle-div {
    width: 100%;
    height: 100%;
    background-position: center 10vh;
    // background-position: center top;
  }
  .app-choose-button{
    font-size: 5vw;
    // margin-left: 1vw;
    width: 10vw;
  }
  .header-info{
    margin-left: 7vw;
  }
  #contact_button{
    font-size: 3vw;
  }
  .join-info-header{
    font-size:4vw;
  }
  .join-info-title{
    font-size:4vw;
    height: 5.5vw;
  }
  .join-info-content{
    font-size:2.5vw;
  }
  .contact-logo{
    font-size: 2vw;
  }
  .contact-copyright{
    font-size: 1.5vw;
  }
  .image-container-role {
    top:15.2vw;
  }
  .show-btn {
    width: 168px;
    height: 26px;
  }
  .app-head{
    font-size: 2vw;
  }
  .app-head-title{
    font-size: 2.5vw;
  }
  #number-text{
    font-size: 3vw;
  }
}
@media (min-width: 768px)  and (max-width: 959px)  {
  .back-video{
    height: 35vh; /* Adjust the height as needed */
  }
  .together-title{
    font-size:6vw;
    line-height: 6vh;
  }
  .together-content{
    margin-top: 3vw;
    font-size: 4vw;
  }
  #app-img-status-not-hide{
    border-radius: 30px 30px 0px 0px; 
  }
  .content-not-hide{
    border-radius: 0px 0px 30px 30px; 
  }
  .temp1{
    margin-top: -5vw;
  }
  .temp2{
    margin-top: -5vw;
  }
  .comment-swiper{
    height: 75%;
  }
  .join-us-both{
    width: 55%;
  }
  .number-change{
    font-size: 7.5vw  !important;
  }
  #number-text-info{
    font-size: 2vw;
  }
  .app-title{
    font-size: 4vw; 
  }
  .app-body{
    margin-top: 4vh;
  }
  .comment{
    font-size:2.5vw;
  }
  .our-people{
    width: 150%;
    margin: 5vw auto;
    margin-left: -25vw;
    font-size:2vw;
  }
  .traslate-div{
    height: 100vh;
  }
  .app-choose-button{
    font-size: 2vw;
    // margin-left: 0vw;
    width: 5.2vw;
  }
  .header-info{
    margin-left: 3vw;
  }
  .middle-div {
    background-position: center top;
    // background-image: url('../assets/test1.png');
  }
  #contact_button{
    font-size: 1.5vw;
  }
  .join-info-header{
    font-size:2vw;
  }
  .join-info-title{
    font-size:2vw;
    height: 3.5vw;
  }
  .join-info-content{
    font-size:1.5vw;
  }
  .contact-logo{
    font-size: 2vw;
  }
  .contact-copyright{
    font-size: 1.5vw;
  }
  .image-container-role {
    top:16.5vw;
  }
  .app-head{
    font-size: 0.8vw;
  }
  .app-head-title{
    font-size: 1vw;
  }
  #about_us {
    margin-top: 10vw;
    position: relative;
  }
  #number-text{
    font-size: 2vw;
  }
}
@media (min-width: 960px)  and (max-width: 1279px){
  .back-video{
    height: 95vh; /* Adjust the height as needed */
  }
  .together-title{
    font-size:5vw;
    line-height: 11vh;
  }
  .together-content{
    margin-top: 3vw;
    font-size: 2vw;
  }
  #app-img-status-not-hide{
    border-radius: 30px 30px 0px 0px; 
  }
  .content-not-hide{
    border-radius: 0px 0px 30px 30px; 
  }
  .temp1{
    margin-top: -5vw;
  }
  .temp2{
    margin-top: -5vw;
  }
  .comment-swiper{
    height: 85%;
  }
  .join-us-both{
    width: 55%;
  }
  .number-change{
    font-size: 7.5vw  !important;
  }
  #number-text-info{
    font-size: 2vw;
  }
  .app-title{
    font-size: 4vw; 
  }
  .app-body{
    margin-top: 4vh;
  }
  .comment{
    font-size:2.5vw;
  }
  .our-people{
    width: 150%;
    margin: 5vw auto;
    margin-left: -25vw;
    font-size:2vw;
  }
  .traslate-div{
    height: 100vh;
  }
  .app-choose-button{
    font-size: 2vw;
    // margin-left: 0vw;
    width: 5.2vw;
  }
  .header-info{
    margin-left: 3vw;
  }
  .middle-div {
    background-position: center top;
    // background-image: url('../assets/test1.png');
  }
  #contact_button{
    font-size: 1.5vw;
  }
  .join-info-header{
    font-size:2vw;
  }
  .join-info-title{
    font-size:2vw;
    height: 3.5vw;
  }
  .join-info-content{
    font-size:1.5vw;
  }
  .contact-logo{
    font-size: 2vw;
  }
  .contact-copyright{
    font-size: 1.5vw;
  }
  .image-container-role {
    top:17.2vw;
  }
  .app-head{
    font-size: 0.8vw;
  }
  .app-head-title{
    font-size: 1vw;
  }
  #about_us {
    margin-top: 10vw;
    position: relative;
  }
  .navigation:hover {
    color: rgb(255,52,245);
    border-color: rgb(255,52,245);
    font-weight: bold;
    cursor: pointer;
  }
  #number-text{
    font-size: 2vw;
  }
}
@media (min-width: 1280px)  and (max-width: 1919px) {
  .back-video{
    height: 95vh; /* Adjust the height as needed */
  }
  .together-title{
    font-size:5vw;
    line-height: 11vh;
  }
  .together-content{
    margin-top: 3vw;
    font-size: 2vw;
  }
  #app-img-status-not-hide{
    border-radius: 30px 30px 0px 0px; 
  }
  .content-not-hide{
    border-radius: 0px 0px 30px 30px; 
  }
  .temp1{
    margin-top: -5vw;
  }
  .temp2{
    margin-top: -5vw;
  }
  .comment-swiper{
    height: 85%;
  }
  .join-us-both{
    width: 55%;
  }
  .number-change{
    font-size: 7.5vw  !important;
  }
  #number-text-info{
    font-size: 2vw;
  }
  .app-title{
    font-size: 4vw; 
  }
  .app-body{
    margin-top: 4vh;
  }
  .comment{
    font-size:2.5vw;
  }
  .our-people{
    width: 150%;
    margin: 5vw auto;
    margin-left: -25vw;
    font-size:2vw;
  }
  .traslate-div{
    height: 100vh;
  }
  .title{
    margin-left: -2vw;
  }
  .middle-div {
    // background-size: 100% 300%;
    background-position: center 10vh;
    // width: 90%;
    background-image: url('https://instafun-r2.huskymobile.com/photo1.jpg');
  }
  .app-choose-button{
    font-size: 2vw;
    // margin-left: 0vw;
    width: 5.2vw;
  }
  .header-info{
    margin-left: 3vw;
  }
  // .middle-div {
  //   background-position: center;
  // }
  #contact_button{
    font-size: 1.5vw;
  }
  .join-info-header{
    font-size:2vw;
  }
  .join-info-title{
    font-size:2vw;
    height: 3.5vw;
  }
  .join-info-content{
    font-size:1.5vw;
  }
  .contact-logo{
    font-size: 1vw;
  }
  .contact-copyright{
    font-size: 0.6vw;
  }
  .image-container-role {
    top:17.2vw;
  }
  .app-head{
    font-size: 0.8vw;
  }
  .app-head-title{
    font-size: 1vw;
  }
  #about_us {
    margin-top: 10vw;
    position: relative;
  }
  .navigation:hover {
    color: rgb(255,52,245);
    border-color: rgb(255,52,245);
    font-weight: bold;
    cursor: pointer;
  }
  #number-text{
    font-size: 2vw;
  }
}
@media (min-width: 1920px) {
  .back-video{
    height: 95vh; /* Adjust the height as needed */
  }
  .together-title{
    font-size:5vw;
    line-height: 11vh;
  }
  .together-content{
    margin-top: 3vw;
    font-size: 2vw;
  }
  #app-img-status-not-hide{
    border-radius: 30px 30px 0px 0px; 
  }
  .content-not-hide{
    border-radius: 0px 0px 30px 30px; 
  }
  .temp1{
    margin-top: -7vw;
  }
  .temp2{
    margin-top: -7vw;
  }
  .comment-swiper{
    height: 85%;
  }
  .join-us-both{
    width: 55%;
  }
  .number-change{
    font-size: 7.5vw  !important;
  }
  #number-text-info{
    font-size: 2vw;
  }
  .app-title{
    font-size: 4vw; 
  }
  .app-body{
    margin-top: 4vh;
  }
  .comment{
    font-size:2.5vw;
  }
  .our-people{
    width: 150%;
    margin: 5vw auto;
    margin-left: -25vw;
    font-size:2vw;
  }
  .traslate-div{
    height: 100vh;
  }
  .middle-div {
    // background-size: 100% auto;
    background-position: center 9vh;
    background-image: url('https://instafun-r2.huskymobile.com/photo1.jpg');
  }
  .title{
    margin-left: -2vw;
  }
  .app-choose-button{
    font-size: 2vw;
    // margin-left: 0vw;
    width: 5.2vw;
  }
  .header-info{
    margin-left: 3vw;
  }
  // .middle-div {
  //   background-position: center;
  // }
  #contact_button{
    font-size: 1.5vw;
  }
  .join-info-header{
    font-size:2vw;
  }
  .join-info-title{
    font-size:2vw;
    height: 3.5vw;
  }
  .join-info-content{
    font-size:1.5vw;
  }
  .contact-logo{
    font-size: 1vw;
  }
  .contact-copyright{
    font-size: 0.6vw;
  }
  .image-container-role {
    top:17.6vw;
  }
  .app-head{
    font-size: 0.8vw;
  }
  .app-head-title{
    font-size: 1vw;
  }
  #about_us {
    margin-top: 10vw;
    position: relative;
  }
  .navigation:hover {
    color: rgb(255,52,245);
    border-color: rgb(255,52,245);
    font-weight: bold;
    cursor: pointer;
  }
  #number-text{
    font-size: 2vw;
  }
}
// @media (max-width: 280px) {
//     /* 在这里添加galaxy fold 280*653尺寸下的样式 */
//   .temp1{
//     background-size: cover;
//     background-repeat: no-repeat;
//     // width: 100%;
//     height: 15vw;
//     background-image: url('../assets/yun4.png');
//     animation: loop1 2s linear infinite alternate;
//     scale: 1.8;
//     margin-top: 15vw;
//   }
//   .temp2{
//     background-size: cover;
//     background-repeat: no-repeat;
//     height: 15vw;
//     background-image: url('../assets/yun3.png');
//     animation: loop1 2s linear infinite alternate;
//     scale: 1.8;
//     margin-top: 15vw;
//   }
//   .app-info-title{
//     font-size: 5vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     margin-left: 7.5vw;
//   }
//   .app-info-content{
//     font-size: 1.0vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .app-info-content-all{
//     line-height: 7vw;
//     margin-top:24.7vw;
//   }
//   .app-info-header{
//     text-align: left; 
//     font-size: 5vw;  
//     font-family: 'MyCustomFont-MB', sans-serif;
//     position: absolute;
//     top:18%;
//     left:4.5vw
//   }
//   .content-not-hide-content{
//     margin-left: 3.5vw;
//   }
//   .overlay {
//     height: 100%;
//     position: absolute;
//     top: 64.6%;
//     left: 0;
//     color:white;
//     background-image: url('../assets/iconzhe.png');
//     background-size: 100% 100%;
//     // border-radius: 50px;
//     transition: transform 0.2s ease;
//   }
//   .overlay:hover{
//   // opacity: 1;
//     transform: translateY(-63%);
//   }
//   .container:hover .overlay{
//     transform: translateY(-63%);
//   }
//   .zoom-image {
//     transition: transform 0.3s;
//     margin-left: 11.5vw; 
//     margin-top: 1.5vw;
//     margin-right: 1.5vw;
//   }
//   #phone-app-header-size{
//     font-size: 5vw;  
//   }
//   .join-info-header{
//     font-family: 'MyCustomFont-MB', sans-serif;
//     text-align: center;
//     font-size:3.5vw;
//     position: relative;
//     margin-top: 3vw;
//   }
//   .join-info-title{
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     // text-align: left;
//     background-image: url('../assets/wire.png');
//     background-size: 8vw 1.5vw;
//     background-position-y: 120%;
//     font-size:3.4vw;
//     height: 4.5vw;
//     text-align: left;
//     color:rgb(23, 3, 94);
//   }
//   #galaxy-fold-join-title{
//     line-height: 4vw;
//   } 
//   #galaxy-fold-join-content{
//     margin-top: 3.5vw;
//   } 
//   .join-info-content{
//     font-family: 'MyCustomFont-MM', sans-serif;
//     // background-image: url('../assets/dian1.png');
//     background-size: 1vw 1vw;
//     font-size: 3.4vw;
//   }
//   .join-us-both{
//     width: 85%;
//   }
//   .app-info{
//     position: relative;
//     background-size: 350% 100%;
//     background-position: center center;
//     // background-repeat: repeat;
//     background-image: url('../assets/2bg.png');
//     // background-color: #007dff;
//     // background-size: 10vw;
//     // border-bottom-left-radius: 40vw;
//     // border-bottom-right-radius: 40vw;
//     // margin-top: 10vw;
//   }
//   .app-footer{
//     height: 3vh;
//     // background-color: transparent;
//   }
//   .number-change{
//     // color: rgb(255, 78, 153);
//     text-align: center;
//     font-size: 16vw !important;
//     font-family: 'MyCustomFont-MB', sans-serif !important;
//     text-shadow: 0.5vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
//     -webkit-text-stroke: 0.4vw white;
//     -webkit-text-fill-color:  rgb(255, 78, 153);
//   }
//   #number-text{
//     color: rgb(23, 3, 94);
//     font-size: 4vw !important;
//     font-family: 'MyCustomFont-MV', sans-serif;
//   }
//   .app-title{
//     color: white; 
//     font-size: 8vw !important; 
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .image-container {
//     max-height: 83vw;
//     overflow: hidden;
//     background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
//     color:white !important;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     z-index: -1;
//   }
//   .cropped-image{
//     left: 15%;
//     margin-top: 25vw;
//     transform: scale(0.9);
//     border-radius: 85vw 10vw;
//     border: 1vw solid white;
//   }
//   .image-container-role {
//     position: absolute;
//     top:50vw;
//     left: 0;
//     max-width: 50%;
//   }
//   .header-title{
//     font-size: 6vw;
//     line-height: 6vw;
//   }
//   .header-content{
//     font-size: 1vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
//   .header-info{
//     // display: flex;
//     // justify-content: center;
//     // align-items: top;
//     position: absolute;
//     top:35%;
//     // margin-left: 25%;
//     margin-left: 10vw;
//   }
//   .ball-back{
//     // position: relative;
//     background-image: url('../assets/qiu.png');
//     background-size: 100% auto;
//     // background-repeat: no-repeat;
//     // background-position: bottom center;
//     // background-position: 0vw 95%;
//   }
//   .item-container .number-change {
//     margin-top: 0; /* 设置 margin-top 为 0 */
//     margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//     max-height: 16vw;
//   }
//   .together-all{
//     width: 70%;
//   }
//   .together-title{
//     font-size:6vw;
//   }
//   .together-content{
//     margin-top: 3vw;
//     font-size: 4vw;
//   }
// }
//  /* 应用于手机的样式 */
// @media (min-width: 281px)  and (max-width: 767px) {
//     /* 在这里添加手机尺寸下的样式 */
//   .temp1{
//     background-size: cover;
//     background-repeat: no-repeat;
//     // width: 100%;
//     height: 15vw;
//     background-image: url('../assets/yun4.png');
//     animation: loop1 2s linear infinite alternate;
//     scale: 1.8;
//     margin-top: 17.5vw;
//   }
//   .temp2{
//     background-size: cover;
//     background-repeat: no-repeat;
//     height: 15vw;
//     background-image: url('../assets/yun3.png');
//     animation: loop1 2s linear infinite alternate;
//     scale: 1.8;
//     margin-top: 17.5vw;
//   }
//   .app-info-title{
//     font-size: 5vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     margin-left: 7.5vw;
//   }
//   .app-info-content{
//     font-size: 2.8vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .app-info-content-all{
//     margin-top:25.5vw;
//   }
//   .app-info-header{
//     text-align: left; 
//     font-size: 5vw;  
//     font-family: 'MyCustomFont-MB', sans-serif;
//     position: absolute;
//     bottom:70%;
//     left:4.5vw
//   }
//   .content-not-hide-content{
//     margin-left: 3vw;
//   }
//   .overlay {
//     height: 100%;
//     position: absolute;
//     top: 64.6%;
//     left: 0;
//     color:white;
//     background-image: url('../assets/iconzhe.png');
//     background-size: 100% 100%;
//     // border-radius: 50px;
//     transition: transform 0.2s ease;
//   }
//   .overlay:hover{
//   // opacity: 1;
//     transform: translateY(-63%);
//   }
//   .container:hover .overlay{
//     transform: translateY(-63%);
//   }
//   .zoom-image {
//     transition: transform 0.3s;
//     margin-left: 8.5vw; 
//     margin-top: 1.5vw;
//     margin-right: 1vw;
//   }
//   #phone-app-header-size{
//     font-size: 5vw;  
//   }
//   .join-info-header{
//     font-family: 'MyCustomFont-MB', sans-serif;
//     text-align: center;
//     font-size:3.5vw;
//     position: relative;
//     margin-top: 4vw;
//   }
//   .join-info-title{
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     // text-align: left;
//     background-image: url('../assets/wire.png');
//     background-size: 4vw 0.5vw;
//     background-position-y: 100%;
//     font-size:3.4vw;
//     height: 4.5vw;
//     text-align: left;
//     color:rgb(23, 3, 94);
//   }
//   .join-info-content{
//     font-family: 'MyCustomFont-MV', sans-serif;
//     // background-image: url('../assets/dian1.png');
//     background-size: 1vw 1vw;
//     font-size: 3.4vw;
//     color:rgb(23, 3, 94);
//   }
//   .join-us-both{
//     width: 85%;
//   }
//   .app-info{
//     position: relative;
//     background-size: 350% 100%;
//     background-position: center center;
//     // background-repeat: repeat;
//     background-image: url('../assets/2bg.png');
//     // background-color: #007dff;
//     // background-size: 10vw;
//     // border-bottom-left-radius: 40vw;
//     // border-bottom-right-radius: 40vw;
//     // margin-top: 10vw;
//   }
//   .app-footer{
//     height: 25vw;
//     // background-color: transparent;
//   }
//   .number-change{
//     // color: rgb(255, 78, 153);
//     text-align: center;
//     font-size: 12vw !important;
//     font-family: 'MyCustomFont-MB', sans-serif !important;
//     text-shadow: 0.5vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
//     -webkit-text-stroke: 0.4vw white;
//     -webkit-text-fill-color:  rgb(255, 78, 153);
//   }
//   #number-text{
//     color: rgb(23, 3, 94);
//     font-size: 5vw !important;
//     font-family: 'MyCustomFont-MV', sans-serif;
//   }
//   .app-title{
//     color: white; 
//     font-size: 8vw !important; 
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .image-container {
//     max-height: 83vw;
//     overflow: hidden;
//     background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
//     color:white !important;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     z-index: -1;
//   }
//   .cropped-image{
//     left: 17%;
//     margin-top: 35%;
//     transform: scale(0.8);
//     border-radius: 85vw 10vw;
//     border: 1vw solid white;
//   }
//   .image-container-role {
//     position: absolute;
//     top:63.5%;
//     left: 0;
//     max-width: 45%;
//   }
//   .header-title{
//     font-size: 9vw;
//     line-height: 9vw;
//   }
//   .header-content{
//     font-size: 4vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
//   .header-info{
//     // display: flex;
//     // justify-content: center;
//     // align-items: top;
//     position: absolute;
//     top:32%;
//     // margin-left: 25%;
//     margin-left: 8vw;
//   }
//   .ball-back{
//     // position: relative;
//     background-image: url('../assets/qiu.png');
//     background-size: 100% auto;
//     // background-repeat: no-repeat;
//     // background-position: bottom center;
//     // background-position: 0vw 95%;
//   }
//   .item-container .number-change {
//     margin-top: 0; /* 设置 margin-top 为 0 */
//     margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//     max-height: 12vw;
//   }
//   .app-choose-button{
//   background-image: url('../assets/choose1.png');
//   // background-size: cover;
//   background-position: center center;
//   background-size: auto 100%;
//   font-family: 'MyCustomFont-MB', sans-serif;
//   color: white;
//   font-size: 1.5vw;
//   // margin-left: 1vw;
//   width: 16vw;
// }
//   .together-all{
//     width: 70%;
//   }
//   .together-title{
//     font-size:6vw;
//     font-weight: bold;
//   }
//   .together-content{
//     margin-top: 3vw;
//     font-size: 4vw;
//   }
// }
// @media (min-width: 768px)  and (max-width: 959px) {
//     /* 在这里添加平板尺寸下的样式 */
//   .temp1{
//     background-size: cover;
//     background-repeat: no-repeat;
//     // width: 100%;
//     height: 27vw;
//     background-image: url('../assets/yun4.png');
//     animation: loop1 2s linear infinite alternate;
//     margin-top: 8vw;
//     }
//   .temp2{
//       background-size: cover;
//       background-repeat: no-repeat;
//       // width: 100%;
//       height: 27vw;
//       background-image: url('../assets/yun3.png');
//       animation: loop1 2s linear infinite alternate;
//       margin-top: 8vw;
//     }
//   .app-info-title{
//     font-size: 2.5vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     margin-left: 3.5vw;
//   }
//   .app-info-content{
//     font-size: 1.1vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .app-info-content-all{
//     margin-top:12vw;
//   }
//   .app-info-header{
//     text-align: left; 
//     font-size: 2.8vw;  
//     font-family: 'MyCustomFont-MB', sans-serif;
//     position: absolute;
//     bottom:23.5vw;
//     left:2.5vw
//   }
//   .content-not-hide-content{
//     margin-left: 2vw;
//   }
//   .overlay {
//     height: 100%;
//     position: absolute;
//     top: 64.6%;
//     left: 0;
//     color:white;
//     background-image: url('../assets/iconzhe.png');
//     background-size: 100% 100%;
//     // border-radius: 50px;
//     transition: transform 0.2s ease;
//   }
//   .overlay:hover{
//   // opacity: 1;
//     transform: translateY(-63%);
//   }
//   .container:hover .overlay{
//     transform: translateY(-63%);
//   }
//   .zoom-image {
//     transition: transform 0.2s;
//     margin-left: 3.5vw; 
//     margin-top: 0.8vw;
//   }
//   .join-info-header{
//     font-family: 'MyCustomFont-MB', sans-serif;
//     text-align: center;
//     font-size:2vw;
//     position: relative;
//     margin-top: 10vw;
//   }
//   .join-info-title{
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     // text-align: left;
//     background-image: url('../assets/wire.png');
//     background-size: 4vw 0.5vw;
//     background-position-y: 100%;
//     font-size:2vw;
//     height: 3.5vw;
//     text-align: left;
//     color:rgb(23, 3, 94);
//   }
//   .join-info-content{
//     font-family: 'MyCustomFont-MV', sans-serif;
//     // background-image: url('../assets/dian1.png');
//     background-size: 1vw 1vw;
//     font-size: 2vw;
//     color:rgb(23, 3, 94);
//   }
//   .join-us-both{
//     width: 55%;
//   }
//   .app-info{
//     position: relative;
//     background-size: 100% 100%;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-image: url('../assets/2bg.png');
//     margin-top: 10vw;
//     // z-index: -1;
//     // height: 70vw;
//   }
//   .app-footer{
//     height: 20vh;
//   }
//   .number-change{
//     // color: rgb(255, 78, 153);
//     text-align: center;
//     font-size: 7vw !important;
//     font-family: 'MyCustomFont-MB', sans-serif !important;
//     text-shadow: 0.5vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
//     -webkit-text-stroke: 0.4vw white;
//     -webkit-text-fill-color:  rgb(255, 78, 153);
//     margin-bottom: 0;
//   }
//   #number-text{
//     color: rgb(23, 3, 94);
//     font-size: 3vw !important;
//     font-family: 'MyCustomFont-MV', sans-serif;
//   }
//   .app-title{
//     color: white; 
//     font-size: 4vw !important; 
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .image-container {
//     max-height: 43vw;
//     overflow: hidden;
//     background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
//     color:white !important;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     // z-index: -1;
//   }
//   .cropped-image{
//     left: 13%;
//     border-radius: 85vw 10vw;
//     border: 1vw solid white;
//     z-index: 0;
//     transform: scale(0.9);
//   }
//   .image-container-role {
//     position: absolute;
//     top:48%;
//     left: 0;
//     max-width: 35%;
//   }
//   .header-title{
//     font-size: 4.5vw;
//     line-height: 4.5vw;
//   }
//   .header-content{
//     font-size: 2vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
//   .header-info{
//     position: absolute;
//     top:9vw;
//     margin-left: 5vw;
//   }
//   .ball-back{
//     // position: relative;
//     background-image: url('../assets/qiu.png');
//     background-size: 100% auto;
//     // background-repeat: no-repeat;
//     // background-position: center bottom;
//     // background-repeat: no-repeat;
//     // z-index: -1;
//     // background-position: 0vw 40vw;
//   }
//   .item-container .number-change {
//     margin-top: 0; /* 设置 margin-top 为 0 */
//     margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//     max-height: 8vw;
//   }
//   .app-choose-button{
//   background-image: url('../assets/choose1.png');
//   // background-size: cover;
//   background-position: center center;
//   background-size: auto 100%;
//   font-family: 'MyCustomFont-MB', sans-serif;
//   color: white;
//   font-size: 1.5vw;
//   // margin-left: 1vw;
//   width: 9vw;
// }
// .together-all{
//     width: 70%;
//   }
//   .together-title{
//     font-size:5vw;
//   }
//   .together-content{
//     margin-top: 3vw;
//     font-size: 3vw;
//   }
// }
//   /* 应用于电脑的样式 */
// @media (min-width: 960px)  and (max-width: 1279px) {
//     /* 在这里添加电脑尺寸下的样式 */
//   .temp1{
//     background-size: cover;
//     background-repeat: no-repeat;
//     // width: 100%;
//     height: 15vw;
//     background-image: url('../assets/yun4.png');
//     animation: loop1 2s linear infinite alternate;
//     margin-top: -2vw;
//     }
//   .temp2{
//       background-size: cover;
//       background-repeat: no-repeat;
//       // width: 100%;
//       height: 15vw;
//       background-image: url('../assets/yun3.png');
//       animation: loop1 2s linear infinite alternate;
//       margin-top: -2vw;
//     }
//   .app-info-title{
//     font-size: 1.5vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     margin-left: 2vw;
//   }
//   .app-info-content{
//     font-size: 0.8vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .app-info-content-all{
//     margin-top:35%;
//   }
//   .app-info-header{
//     text-align: left; 
//     font-size: 1.5vw;  
//     font-family: 'MyCustomFont-MB', sans-serif;
//     position: absolute;
//     bottom:71%;
//     left:1.5vw
//   }
//   .content-not-hide-content{
//     margin-left: 1vw;
//   }
//   .overlay {
//     height: 100%;
//     position: absolute;
//     top: 64.6%;
//     left: 0;
//     color:white;
//     background-image: url('../assets/iconzhe.png');
//     background-size: 100% 100%;
//     // border-radius: 50px;
//     transition: transform 0.2s ease;
//   }
//   .overlay:hover{
//   // opacity: 1;
//     transform: translateY(-63%);
//   }
//   .container:hover .overlay{
//     transform: translateY(-63%);
//   }
//   .zoom-image {
//     transition: transform 0.3s;
//     margin-left: 2vw; 
//     margin-top: 0.5vw;
//   }
//   .join-info-header{
//     font-family: 'MyCustomFont-MB', sans-serif;
//     text-align: center;
//     font-size:2vw;
//     position: relative;
//     margin-top: 10vw;
//   }
//   .join-info-title{
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     // text-align: left;
//     background-image: url('../assets/wire.png');
//     background-size: 4vw 0.5vw;
//     background-position-y: 100%;
//     font-size:2vw;
//     height: 3.5vw;
//     text-align: left;
//     color:rgb(23, 3, 94);
//   }
//   .join-info-content{
//     font-family: 'MyCustomFont-MV', sans-serif;
//     // background-image: url('../assets/dian1.png');
//     background-size: 1vw 1vw;
//     font-size: 2vw;
//     color:rgb(23, 3, 94);
//   }
//   .join-us-both{
//     width: 55%;
//   }
//   .app-info{
//     position: relative;
//     background-size: 100% 100%;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-image: url('../assets/2bg.png');
//     margin-top: 10vw;
//     // z-index: -1;
//     // height: 70vw;
//   }
//   .app-footer{
//     height: 20vh;
//   }
//   .number-change{
//     // color: rgb(255, 78, 153);
//     text-align: center;
//     font-size: 7vw !important;
//     font-family: 'MyCustomFont-MB', sans-serif !important;
//     text-shadow: 0.5vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
//     -webkit-text-stroke: 0.4vw white;
//     -webkit-text-fill-color:  rgb(255, 78, 153);
//     margin-bottom: 0;
//   }
//   #number-text{
//     color: rgb(23, 3, 94);
//     font-size: 3vw !important;
//     font-family: 'MyCustomFont-MV', sans-serif;
//   }
//   .app-title{
//     color: white; 
//     font-size: 4vw !important; 
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .image-container {
//     max-height: 43vw;
//     overflow: hidden;
//     background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
//     color:white !important;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     // z-index: -1;
//   }
//   .cropped-image{
//     left: 13%;
//     border-radius: 85vw 10vw;
//     border: 1vw solid white;
//     z-index: 0;
//     transform: scale(0.9);
//   }
//   .image-container-role {
//     position: absolute;
//     top:48.5%;
//     left: 0;
//     max-width: 35%;
//   }
//   .header-title{
//     font-size: 4.5vw;
//     line-height: 4.5vw;
//   }
//   .header-content{
//     font-size: 2vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
//   .header-info{
//     position: absolute;
//     top:9vw;
//     margin-left: 3vw;
//   }
//   .ball-back{
//     position: relative;
//     background-image: url('../assets/qiu.png');
//     background-size: 100% auto;
//     background-repeat: no-repeat;
//     background-position: center bottom;
//     background-repeat: no-repeat;
//     // z-index: -1;
//     // background-position: 0vw 40vw;
//   }
//   .item-container .number-change {
//   margin-top: 0; /* 设置 margin-top 为 0 */
//   margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//   max-height: 8vw;
// }
// .app-choose-button{
//   background-image: url('../assets/choose1.png');
//   // background-size: cover;
//   background-position: center center;
//   background-size: auto 100%;
//   font-family: 'MyCustomFont-MB', sans-serif;
//   color: white;
//   font-size: 1.5vw;
//   // margin-left: 1vw;
//   width: 7vw;
// }
// .together-all{
//     width: 70%;
//   }
//   .together-title{
//     font-size:3vw;
//   }
//   .together-content{
//     margin-top: 3vw;
//   }
// }
// @media (min-width: 1280px) and (max-width: 1919px) {
    /* 在这里添加电脑尺寸下的样式 */
  .temp1{
    // margin-top: -7vw;
    background-size: cover;
    background-repeat: no-repeat;
    // width: 100%;
    height: 15vw;
    background-image: url('https://instafun-r2.huskymobile.com/yun4.png');
    animation: loop1 2s linear infinite alternate;
    }
  .temp2{
    // margin-top: -7vw;
    background-size: cover;
    background-repeat: no-repeat;
    // width: 100%;
    height: 15vw;
    background-image: url('https://instafun-r2.huskymobile.com/yun3.png');
    animation: loop1 2s linear infinite alternate;
  }
  .app-info-title{
    font-size: 1.2vw;
    font-family: 'MyCustomFont-MB', sans-serif;
    margin-left: 2.5vw;
  }
  .app-info-content{
    font-size: 0.8vw;
    font-family: 'MyCustomFont-MB', sans-serif;
  }
  .app-info-content-all{
    margin-top:43%;
  }
  .app-info-header{
    text-align: left; 
    font-size: 1.5vw;  
    font-family: 'MyCustomFont-MB', sans-serif;
    position: absolute;
    // top:2.5vw;
    bottom:65%;
    left:1.8vw
  }
  .app-head{
    // font-size: 2vw;
    font-family: 'MyCustomFont-MM', sans-serif;color:gray;
    margin-top: 0.5vw;
  }
  .app-head-title{
    // font-size: 2.5vw;
    font-family: 'MyCustomFont-MB', sans-serif;
    margin-top: 0.2vw;
    white-space: nowrap;
  }
  .content-not-hide-content{
    margin-left: 1vw;
  }
  .overlay {
    height: 100%;
    position: absolute;
    top: 55%;
    left: 0;
    color:white;
    background-image: url('https://instafun-r2.huskymobile.com/iconzhe.png');
    background-size: 100% 100%;
    // border-radius: 50px;
    transition: transform 0.2s ease;
  }
  .overlay:hover{
  // opacity: 1;
    transform: translateY(-55%);
  }
  .container:hover .overlay{
    transform: translateY(-55%);
  }
  .zoom-image {
    transition: transform 0.3s;
    margin-left: 2.7vw; 
    margin-top: 0.5vw;
  }
  .join-info-header{
    // font-size:2vw;
    font-family: 'MyCustomFont-MB', sans-serif;
    text-align: center;
    position: relative;
    margin-top: 10vw;
  }
  .join-info-title{
    // font-size:2vw;
    font-family: 'MyCustomFont-MVB', sans-serif;
    // text-align: left;
    background-image: url('https://instafun-r2.huskymobile.com/wire.png');
    background-size: 4vw 0.5vw;
    background-position-y: 100%;
    // height: 3.5vw;
    text-align: left;
    color:rgb(23, 3, 94);
  }
  .join-info-content{
    // font-size: 1.5vw;
    font-family: 'MyCustomFont-MV', sans-serif;
    // background-image: url('../assets/dian1.png');
    background-size: 1vw 1vw;
    color:rgb(23, 3, 94);
  }
  
  .app-info{
    position: relative;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('https://instafun-r2.huskymobile.com/2bg.png');
    margin-top: 10vw;
    // z-index: -1;
    // height: 70vw;
  }
  .app-footer{
    height: 20vh;
  }
  .number-change{
    // font-size: 7.5vw;
    // color: rgb(255, 78, 153);
    text-align: center;
    font-family: 'MyCustomFont-MB', sans-serif !important;
    text-shadow: 1.0vw 1.0vw 1.0vw rgba(0, 0, 0, 0.5);
    -webkit-text-stroke: 0.5vw white;
    -webkit-text-fill-color:  rgb(255, 78, 153);
    // margin-bottom: 0;
  }
  #number-text{
    // font-size: 2vw;
    color: rgb(23, 3, 94);
    font-family: 'MyCustomFont-MV', sans-serif;
    margin-top: -1.5vw;
    margin-left: -3vw;
  }
  .app-title{
    // font-size: 4vw; 
    color: white; 
    font-family: 'MyCustomFont-MB', sans-serif;
  }
  .image-container {
    max-height: 43vw;
    overflow: hidden;
    // background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
    color:white !important;
    font-family: 'MyCustomFont-MB', sans-serif;
    // z-index: -1;
  }
  .cropped-image{
    margin-left: 11%;
    border-radius: 90vw 10vw;
    border: 1vw solid white;
    z-index: 0;
    transform: scale(0.9);
    width: 100%;
    height: auto;
  }
  .image-container-role {
    position: absolute;
    // top:17.2vw;
    left: 0vw;
    max-width: 41%;
    
  }
  .header-title{
    font-size: 4.5vw;
    line-height: 4.5vw;
  }
  .header-content{
    font-size: 2vw;
    font-family: 'MyCustomFont-MM', sans-serif;
  }
  .header-info{
    // margin-left: 3vw;
    position: absolute;
    top:8vw;
  }
  .ball-back{
    // position: relative;
    background-image: url('https://instafun-r2.huskymobile.com/qiu.png');
    background-size: 100% auto;
    // background-repeat: no-repeat;
    // background-position: center bottom;
    // background-repeat: no-repeat;
    // z-index: -1;
    // background-position: 0vw 40vw;
  }
  .item-container .number-change {
    margin-top: 5vw; /* 设置 margin-top 为 0 */
    // max-height: 9vw;
    margin-left: -3vw;
  }
.app-choose-button{
  // font-size: 2vw;
  // margin-left: 0vw;
  // width: 5.2vw;
  background-image: url('https://instafun-r2.huskymobile.com/choose1.png');
  // background-size: cover;
  background-position: center center;
  background-size: auto 100%;
  font-family: 'MyCustomFont-MB', sans-serif;
  color: white;
}
.together-all{
    width: 80%;
  }
.together-title{
  // font-size:5vw;
  // line-height: 11vh;
  font-family: 'MyCustomFont-MB', sans-serif;
}
.together-content{
  // margin-top: 3vw;
  // font-size: 2vw;
  font-family: 'MyCustomFont-MM', sans-serif;
}
// }
// @media (min-width: 1920px) {
//     /* 在这里添加电脑尺寸下的样式 */
//   .temp1{
//     background-size: cover;
//     background-repeat: no-repeat;
//     // width: 100%;
//     height: 15vw;
//     background-image: url('../assets/yun4.png');
//     animation: loop1 2s linear infinite alternate;
//     margin-top: -7vw;
//     }
//   .temp2{
//       background-size: cover;
//       background-repeat: no-repeat;
//       // width: 100%;
//       height: 15vw;
//       background-image: url('../assets/yun3.png');
//       animation: loop1 2s linear infinite alternate;
//       margin-top: -7vw;
//     }
//   .app-info-title{
//     font-size: 1.2vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     margin-left: 3vw;
//   }
//   .app-info-content{
//     font-size: 0.8vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .app-info-content-all{
//     margin-top:9.5vw;
//   }
//   .app-info-header{
//     text-align: left; 
//     font-size: 1.5vw;  
//     font-family: 'MyCustomFont-MB', sans-serif;
//     position: absolute;
//     // top:2.5vw;
//     bottom:55%;
//     left:2.5vw
//   }
//   .content-not-hide-content{
//     margin-left: 1vw;
//   }
//   .zoom-image {
//     transition: transform 0.3s;
//     margin-left: 3.2vw; 
//     margin-top: 0.5vw;
//   }
//   .overlay {
//     height: 100%;
//     position: absolute;
//     top: 49%;
//     left: 0;
//     color:white;
//     background-image: url('../assets/iconzhe.png');
//     background-size: 100% 100%;
//     // border-radius: 50px;
//     transition: transform 0.2s ease;
//   }
//   .overlay:hover{
//   // opacity: 1;
//     transform: translateY(-49%);
//   }
//   .container:hover .overlay{
//     transform: translateY(-49%);
//   }
//   .join-info-header{
//     font-family: 'MyCustomFont-MB', sans-serif;
//     text-align: center;
//     font-size:2vw;
//     position: relative;
//     margin-top: 10vw;
//   }
//   .join-info-title{
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     // text-align: left;
//     background-image: url('../assets/wire.png');
//     background-size: 4vw 0.5vw;
//     background-position-y: 100%;
//     font-size:2vw;
//     height: 3.5vw;
//     text-align: left;
//     color:rgb(23, 3, 94);
//   }
//   .join-info-content{
//     font-family: 'MyCustomFont-MV', sans-serif;
//     // background-image: url('../assets/dian1.png');
//     background-size: 1vw 1vw;
//     font-size: 1.5vw;
//   }
//   .join-us-both{
//     width: 55%;
//   }
//   .app-info{
//     position: relative;
//     background-size: 100% 100%;
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-image: url('../assets/2bg.png');
//     margin-top: 10vw;
//     // z-index: -1;
//     // height: 70vw;
//   }
//   .app-footer{
//     height: 20vh;
//   }
//   .number-change{
//     // color: rgb(255, 78, 153);
//     text-align: center;
//     font-size: 8vw !important;
//     font-family: 'MyCustomFont-MB', sans-serif !important;
//     text-shadow: 0.5vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
//     -webkit-text-stroke: 0.4vw white;
//     -webkit-text-fill-color:  rgb(255, 78, 153);
//     margin-bottom: 0;
//   }
//   #number-text{
//     color: rgb(23, 3, 94);
//     font-size: 2vw !important;
//     font-family: 'MyCustomFont-MV', sans-serif;
//   }
//   .app-title{
//     color: white; 
//     font-size: 4vw !important; 
//     font-family: 'MyCustomFont-MB', sans-serif;
//   }
//   .image-container {
//     max-height: 43vw;
//     overflow: hidden;
//     background-image: linear-gradient(to right, rgb(0,124,255), rgb(203,78,255));
//     color:white !important;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     // z-index: -1;
//   }
//   .cropped-image{
//     left: 13%;
//     border-radius: 85vw 10vw;
//     border: 1vw solid white;
//     z-index: 0;
//     transform: scale(0.9);
//   }
//   .image-container-role {
//     position: absolute;
//     top:17.6vw;
//     left: 0vw;
//     max-width: 41%;
//   }
//   .header-title{
//     font-size: 4.5vw;
//     line-height: 4.5vw;
//   }
//   .header-content{
//     font-size: 2vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
//   .header-info{
//     position: absolute;
//     top:8vw;
//     margin-left: 3vw;
//   }
//   .ball-back{
//     // position: relative;
//     background-image: url('../assets/qiu.png');
//     background-size: 100% auto;
//     // background-repeat: no-repeat;
//     // background-position: center bottom;
//   }
//   .item-container .number-change {
//     margin-top: 8vw; /* 设置 margin-top 为 0 */
//     margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//     max-height: 10vw;
//   }
//   .app-choose-button{
//     background-image: url('../assets/choose1.png');
//     // background-size: cover;
//     background-position: center center;
//     background-size: auto 100%;
//     font-family: 'MyCustomFont-MVB', sans-serif;
//     color: white;
//     font-size: 1.5vw;
//     // margin-left: 1vw;
//     width: 4vw;
//   }
//   .together-all{
//     width: 80%;
//   }
//   .together-title{
//     font-size:5vw;
//     font-family: 'MyCustomFont-MB', sans-serif;
//     line-height: 11vh;
//   }
//   .together-content{
//     margin-top: 3vw;
//     font-size: 2vw;
//     font-family: 'MyCustomFont-MM', sans-serif;
//   }
// }
.video-wrapper {
  position: relative;
  // display: inline-block;
}
.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// .video-container {
//   position: relative;
//   z-index: 1;
// }
.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* 遮罩层的颜色和透明度 */
  z-index: 0;
  display: flex;
  flex-direction: column; /* 设置为垂直方向的 Flex 容器 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  text-align: center; /* 文字水平居中 */
}
.back-video{
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative;
  width: 100%;
  height: 95vh; /* Adjust the height as needed */
  overflow: hidden;
}
.back-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.video-container {
  z-index: 1;
}
.temp3{
  background-size: cover;
  background-repeat: no-repeat;
  // width: 100%;
  height: 15vw;
  background-image: url('https://instafun-r2.huskymobile.com/yun4.png');
  animation: loop2 2s linear infinite alternate;
}
.temp4{
  background-size: cover;
  background-repeat: no-repeat;
  // width: 100%;
  height: 15vw;
  background-image: url('https://instafun-r2.huskymobile.com/yun3.png');
  animation: loop2 2s linear infinite alternate;
}

@keyframes loop1 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
.app-des{
  overflow:hidden
}
#app-img-status{
  object-fit: cover;
  width: 89%; 
  height: 100%;
  border-radius: 30px; 
  overflow: hidden;
}
#app-img-status-not-hide{
  // border-radius: 30px 30px 0px 0px; 
  object-fit: cover;
  // width: 89%; 
  height: 100%;
  overflow: hidden;
}

.content {
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 50px;
  display: flex;
  align-items:end;
  justify-content: left;
  transform: translate(0%);
  font-family: 'MyCustomFont-MB', sans-serif;
  font-size: 1.5vw;
  color: transparent; 
  text-align: left;
}
.content-not-hide{
  // border-radius: 0px 0px 30px 30px; 
  // width: 89%; 
  background-color: white;
}
.content-not-hide-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.content::before {
  content: '';
  position: absolute;
  top: 40%;
  left: -40%;
  width: 180%;
  height: 100%;
  background-size: cover;
  // background-image: url('../assets/iconzhe.png');
  // background-color: rgba(0, 0, 0, 0.1);  /* 背景颜色透明度 */
  border-radius: 50px;
  pointer-events: none; /* 遮罩层不响应鼠标事件 */
  z-index: -1;
}
/* 设置渐变切换文本的样式 */
.transition-text {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* 确保文本在Swiper组件上方显示 */
  font-size: 24px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.2s;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(80%);
}

.appbar {
  font-family: 'MyCustomFont-MM', sans-serif;
  color:white !important;
  background-color:#007dff !important;
}
.content-header {
  font-family: 'MyCustomFont-MB', sans-serif;
  color:white !important;
}


// .carousel {
//   width: 100%;
// }

// .swiper-slide {
//   height: 200px;
// }
// .img-cov{
//   // max-height: 43vw;
//   background-image: url('../assets/zheyuantou.png');
//   background-size: 200% 100%;
//   // width: 100%;
//   // object-fit: cover;
//   z-index: -1;
// }
.contact-all{
    margin-top: 12vw;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
.item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3vw;
  // padding: 10px; /* 设置额外的内边距 */
}
// .item-container .number-change {
//   margin-top: 0; /* 设置 margin-top 为 0 */
//   margin-bottom: 0; /* 设置 margin-bottom 为 0 */
//   max-height: 8vw;
// }

.app-content {
  align-items: center;
  justify-content: center;
}
#app-des{
  font-family: 'MyCustomFont-MM', sans-serif;
}


.zoom-image:hover {
  transform: scale(1.2);
}
.join-all{
  // position: relative;
  // background-size: 100% 100%;
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-image: url('../assets/qiu.png');
  // z-index: -1;
  // margin-top: 10vw;
  // height: 85vw;
  display: flex;
  justify-content: center;
  align-items: top;
  margin-bottom: 5vw;
}


.join-star{
  background-image: url('https://instafun-r2.huskymobile.com/star.png');
  background-size: 1vw 1vw;
}
#roate {
  animation: shake 2s infinite;
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-100deg);
  }
  20% {
    transform: rotate(100deg);
  }
  30% {
    transform: rotate(-200deg);
  }
  40% {
    transform: rotate(200deg);
  }
  50% {
    transform: rotate(-100deg);
  }
  60% {
    transform: rotate(100deg);
  }
  70% {
    transform: rotate(-60deg);
  }
  80% {
    transform: rotate(60deg);
  }
  90% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.contact-info{
  background-image: url('https://instafun-r2.huskymobile.com/3bg.png');
  position: relative;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  // height: 65vh;
  // margin-top: 10vw;
}
#contact_button{
  // font-size: 1.5vw;
  background-image: url('https://instafun-r2.huskymobile.com/contactus.png');
  background-position: center center;
  font-family: 'MyCustomFont-MB', sans-serif;
  color: white;
  white-space: nowrap;
  border-radius: 2vw;
  padding: 0 1.0vw;
  // margin-left: -5%;
}
.begin-header .swiper-pagination-bullet {
  background-color: rgb(255, 255, 255) !important;
  width: 1vw !important;
  height:1vw !important;
  transition: transform 0.3s;
}

.swiper-pagination-bullet:hover {
  transform: scale(1.5);
}
.comment{
  // font-size:2.5vw;
  font-family: 'MyCustomFont-MB', sans-serif;
  text-align: center;
  // margin-top: 5vw;
}
// .comment .swiper-pagination {
//   // position: absolute;
//   // bottom: 20px; /* 距离底部的距离 */
//   // left: 50%;
//   // transform: translateX(-50%);
// }
.comment .swiper-pagination-bullet {
  background-color: gray !important;
  width: 1vw !important;
  height:1vw !important;
  transition: transform 0.3s;
}

.pages-choose{
  // font-family: 'MyCustomFont-MB', sans-serif;
  // text-align: center;
  margin-top: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 10vw;
}

.app-choose-button:hover{
  background-image: url('https://instafun-r2.huskymobile.com/choose2.png');
  color: rgb(67,23,160);
}
.button-clicked {
  background-image: url('https://instafun-r2.huskymobile.com/choose2.png');
  color: rgb(67,23,160);
}
// .apple-img .hover-img:hover {
//   content: url('https://instafun-r2.huskymobile.com/new_card/app_.png');
// }
// .google-img .hover-img:hover {
//   content: url('https://instafun-r2.huskymobile.com/new_card/google_.png');
// }
.video-text{
  font-family: 'MyCustomFont-MV', sans-serif;
  text-align: center;
  font-size:1.6vw;
  position: relative;
  // margin-top: 10vw;
  color:white;
}
.our-people{
  // width: 150%;
  // margin: 5vw auto;
  // margin-left: -25vw;
  // font-size:2vw;
  font-family: 'MyCustomFont-MB', sans-serif;
  margin-top: 5vw;
  text-align: center;
}
.people-image {
  display: flex;
  justify-content: center;
  margin-top: 5vw;
}

.people-image-column {
  text-align: center;
}
/* 样式表中添加下划线效果 */
.show-btn-text {
  text-decoration: underline;
  font-family: 'MyCustomFont-MM', sans-serif;
  font-size: 1vw;
  color: rgb(67,23,160);
}
.show-btn {
  // width: 10vw;
  // height:10vw;
  /* 清除按钮的边框和背景颜色 */
  // border: none;
  // background-color: transparent;
  // background-size: 100% 100%;
  background-size: auto 100%;
  background-position: center;
}
.show-btn:hover{
  cursor: pointer; /* 将光标设置为小手指针样式 */
}
// .show-btn-text:hover{
//   color: rgb(255,52,245);
//   font-weight: bold;
//   cursor: pointer;
// }
.bird {
	background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg);
  // background-image: url('../assets/star.png');
	background-size: auto 100%;
	width: 88px;
	height: 125px;
	will-change: background-position;
	
	animation-name: fly-cycle;
	animation-timing-function: steps(10);
	animation-iteration-count: infinite;

	&--one {
		animation-duration: 1s;
		animation-delay: -0.5s;		
	}
	
	&--two {
		animation-duration: 0.9s;
		animation-delay: -0.75s;
	}
	
	&--three {
		animation-duration: 1.25s;
		animation-delay: -0.25s;
	}
	
	&--four {
		animation-duration: 1.1s;
		animation-delay: -0.5s;
	}

}

.bird-container {
	position: absolute;
	top: 20%;
	left: -10%;
	transform: scale(0) translateX(-10vw);
	will-change: transform;
	
	animation-name: fly-right-one;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	
	&--one {
		animation-duration: 15s;
		animation-delay: 0;
	}
	
	&--two {
		animation-duration: 16s;
		animation-delay: 1s;
	}
	
	&--three {
		animation-duration: 14.6s;
		animation-delay: 9.5s;
	}
	
	&--four {
		animation-duration: 16s;
		animation-delay: 10.25s;
	}
	
}

@keyframes fly-cycle {
	
	100% {
		background-position: -900px 0;
	}
	
}

@keyframes fly-right-one {
	
	0% {
		transform: scale(0.3) translateX(-10vw);
	}
	
	10% {
		transform: translateY(2vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(0vh) translateX(30vw) scale(0.5);
	}
	
	30% {
		transform: translateY(4vh) translateX(50vw) scale(0.6);
	}
	
	40% {
		transform: translateY(2vh) translateX(70vw) scale(0.6);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.6);
	}
	
	60% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.6);
	}
	
}

@keyframes fly-right-two {
	
	0% {
		transform: translateY(-2vh) translateX(-10vw) scale(0.5);
	}
	
	10% {
		transform: translateY(0vh) translateX(10vw) scale(0.4);
	}
	
	20% {
		transform: translateY(-4vh) translateX(30vw) scale(0.6);
	}
	
	30% {
		transform: translateY(1vh) translateX(50vw) scale(0.45);
	}
	
	40% {
		transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
	}
	
	50% {
		transform: translateY(0vh) translateX(90vw) scale(0.45);
	}
	
	51% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
	100% {
		transform: translateY(0vh) translateX(110vw) scale(0.45);
	}
	
}
.star {
  width: 20px;
  height: 20px;
  background-color: white;
  clip-path: polygon(50% 0%, 61.8% 19.1%, 98.2% 35.4%, 74.4% 62.9%, 79.6% 100%, 50% 81.3%, 20.4% 100%, 25.6% 62.9%, 1.8% 35.4%, 38.2% 19.1%);
  position: absolute;
  top: -20px;
  animation: fallingStar 5s linear infinite;
}

@keyframes snowfall {
  to {
    transform: translateY(100vh);
  }
}

@keyframes fallingStar {
  to {
    transform: translateY(100vh) rotate(360deg);
  }
  }
  .pages{
    // text-align: center;
    // width: 5vw;
    // margin-left:42.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pages-button{
    background-color: #007dff;
    border-radius: 5vw;
    margin-left: 5vw;
    width: 3vw;
  }
  .pages-button:hover{
    background-color: red;
  }
</style>
